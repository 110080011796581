import React, { useState } from 'react';

const OfficeForm = () => {
  const [formData, setFormData] = useState({
    compName: '',
    location: '',
    // Add more fields as necessary
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the server
    console.log('Form data submitted:', formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Admin Information</h2>
      <div>
        <label>Company Name:</label>
        <input type="text" name="compName" value={formData.compName} onChange={handleChange} required />
      </div>
      <div>
        <label>Location:</label>
        <input type="text" name="location" value={formData.location} onChange={handleChange} required />
      </div>
      {/* Add more input fields as required */}
      <button type="submit">Submit</button>
    </form>
  );
};

export default OfficeForm;
