// Gallery.js
import React from 'react';
import './Gallery.css';

const images = [
  'https://images.pexels.com/photos/37347/office-sitting-room-executive-sitting.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  'https://img.freepik.com/premium-photo/luxury-executive-office-modern-comfortable-setting_162944-5826.jpg',
  'https://plus.unsplash.com/premium_photo-1661903986017-673f1bd6b47e?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bHV4dXJ5JTIwb2ZmaWNlfGVufDB8fDB8fHww',
];

const Gallery = () => {
  return (
    <div className="gallery">
      {images.map((img, index) => (
        <div key={index} className="gallery-image" style={{ backgroundImage: `url(${img})` }} />
      ))}
    </div>
  );
};

export default Gallery;
