// Breadcrumb.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumb.css'; // Import your styles

// Sample menu data
const menuData = [
  { code: "1001", name: "Master Management", parentCode: "0", path: "MasterManagement" },
  { code: "1101", name: "Office", parentCode: "1001", path: "Offices" },
  { code: "1102", name: "Conference Room", parentCode: "1001", path: "ConferenceRooms" },
  { code: "1103", name: "Assets Group", parentCode: "1001", path: "AssetsGroup" },
  { code: "1104", name: "Assets", parentCode: "1001", path: "Assets" },
  { code: "1105", name: "Customer", parentCode: "1001", path: "Customer" },
  { code: "1106", name: "Vendor", parentCode: "1001", path: "Vendor" },
  { code: "2001", name: "Transaction Management", parentCode: "0", path: "Transaction" },
  { code: "2101", name: "Contract", parentCode: "2001", path: "Contract" },
  { code: "2102", name: "Invoicing", parentCode: "2001", path: "Invoicing" },
  { code: "2103", name: "Payment", parentCode: "2001", path: "Payment" },
  { code: "3001", name: "Conference Management", parentCode: "0", path: "ConferenceRoom" },
  { code: "3101", name: "Booking", parentCode: "3001", path: "Booking" },
  { code: "3102", name: "Credits", parentCode: "3001", path: "Credits" },
  { code: "4001", name: "Visitor Management", parentCode: "0", path: "VisitorManagement" },
  { code: "4101", name: "Visitor In request", parentCode: "4001", path: "VisitorInRequest" },
  { code: "4102", name: "Visitor In Approval", parentCode: "4001", path: "VisitorInApproval" },
  { code: "4103", name: "Visitor reports", parentCode: "4001", path: "VisitorReports" },
  { code: "5001", name: "Support Management", parentCode: "0", path: "SupportManagement" },
  { code: "5101", name: "Raise a Ticket", parentCode: "5001", path: "RaiseTicket" },
  { code: "5102", name: "Reply on Ticket", parentCode: "5001", path: "ReplyOnTicket" },
  { code: "5103", name: "Reports", parentCode: "5001", path: "Reports" },
];

// Function to get the breadcrumb path based on current route
const getBreadcrumbs = (paths) => {
  const breadcrumbs = [];
  let currentCode = null;

  paths.forEach((path) => {
    const menu = menuData.find((item) => item.path === path);
    if (menu) {
      breadcrumbs.push(menu);
      currentCode = menu.code;
    }

    // Find parent menus if exists
    if (currentCode) {
      let parent = menuData.find((item) => item.code === currentCode && item.parentCode !== "0");
      while (parent) {
        breadcrumbs.unshift(parent);
        parent = menuData.find((item) => item.code === parent.parentCode);
      }
    }
  });

  return breadcrumbs;
};

const Breadcrumb = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path);

  const breadcrumbs = getBreadcrumbs(paths);

  return (
    <nav className="breadcrumb">
      <ul>
        {breadcrumbs.map((item, index) => {
          const url = `/${item.path}`;
          return (
            <li key={item.code}>
              <Link to={url}>{item.name}</Link>
              {index < breadcrumbs.length - 1 && <span> &gt; </span>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
