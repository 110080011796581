import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const CostDistributionGraph = () => {
  // Sample data for cost distribution
  const data = [
    { name: 'Employee Salaries', cost: 5000 },
    { name: 'Repair Costs', cost: 2000 },
    { name: 'Utility Bills', cost: 1500 },
    { name: 'Maintenance', cost: 1000 },
    { name: 'Other Expenses', cost: 500 },
  ];

  return (
    <div className="graph-container">
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="cost" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default CostDistributionGraph;
