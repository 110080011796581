import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Sidebar from './Sidebar';
import * as XLSX from 'xlsx';
import './Offices.css';
import DashboardHeader from './DashboardHeader';

const Offices = () => {
  const [offices, setOffices] = useState([
    { id: 1, code: 'O001', description: 'Main Office', capacity: 10 },
    { id: 2, code: 'O002', description: 'Branch Office', capacity: 5 },
  ]);

  const [newOfficeCode, setNewOfficeCode] = useState('');
  const [newOfficeDescription, setNewOfficeDescription] = useState('');
  const [newOfficeCapacity, setNewOfficeCapacity] = useState('');
  const [showAddOffice, setShowAddOffice] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editOffice, setEditOffice] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({ code: '', description: '' });
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const handleAddOffice = () => {
    if (newOfficeCode && newOfficeDescription && newOfficeCapacity) {
      const newOffice = {
        id: offices.length + 1,
        code: newOfficeCode,
        description: newOfficeDescription,
        capacity: newOfficeCapacity,
      };
      setOffices([...offices, newOffice]);
      setNewOfficeCode('');
      setNewOfficeDescription('');
      setNewOfficeCapacity('');
      setShowAddOffice(false);
    }
  };

  const handleDeleteOffice = (id) => {
    setOffices(offices.filter(office => office.id !== id));
  };

  const handleEditOffice = (office) => {
    setEditOffice(office);
    setShowEditPopup(true);
  };

  const handleUpdateOffice = () => {
    setOffices(offices.map(office => (office.id === editOffice.id ? editOffice : office)));
    setShowEditPopup(false);
    setEditOffice(null);
  };

  const handleSearchOffices = () => {
    const results = offices.filter(office => {
      return (
        (searchCriteria.code ? office.code.toLowerCase().includes(searchCriteria.code.toLowerCase()) : true) &&
        (searchCriteria.description ? office.description.toLowerCase().includes(searchCriteria.description.toLowerCase()) : true)
      );
    });
    setSearchResults(results);
    setShowSearchPopup(false); // Close the popup after searching
  };

  return (
    <div className="office">
      <DashboardHeader />

      <div className="office-content">
        <h3>Master creation/Office</h3>
        <h2>Offices</h2>

        <div className="export-buttons">
          <button onClick={() => setShowAddOffice(!showAddOffice)} className="export-button add-button">
            ➕
          </button>
          <button onClick={() => setShowSearchPopup(true)} className="export-button">
            🔍
          </button>
        </div>

        {showAddOffice && (
          <div className="edit-popup">
            <h4>Add New Office</h4>
            <input
              type="text"
              value={newOfficeCode}
              onChange={(e) => setNewOfficeCode(e.target.value)}
              placeholder="Enter office code"
            />
            <input
              type="text"
              value={newOfficeDescription}
              onChange={(e) => setNewOfficeDescription(e.target.value)}
              placeholder="Enter office description"
            />
            <input
              type="number"
              value={newOfficeCapacity}
              onChange={(e) => setNewOfficeCapacity(e.target.value)}
              placeholder="Enter office capacity"
            />
            <div>
              <button onClick={handleAddOffice}>Submit</button>
              <button onClick={() => setShowAddOffice(false)}>Cancel</button>
            </div>
          </div>
        )}

        <table id="officesTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Description</th>
              <th>Capacity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {offices.map((office) => (
              <tr key={office.id}>
                <td>{office.id}</td>
                <td>{office.code}</td>
                <td>{office.description}</td>
                <td>{office.capacity}</td>
                <td>
                  <button onClick={() => handleEditOffice(office)}>
                    <img src="https://freesvg.org/img/edit-blue.png" alt="Edit" className="icon" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditPopup && editOffice && (
          <div className="edit-popup">
            <h4>Edit Office</h4>
            <input
              type="text"
              value={editOffice.code}
              onChange={(e) => setEditOffice({ ...editOffice, code: e.target.value })}
              placeholder="Edit office code"
            />
            <input
              type="text"
              value={editOffice.description}
              onChange={(e) => setEditOffice({ ...editOffice, description: e.target.value })}
              placeholder="Edit office description"
            />
            <input
              type="number"
              value={editOffice.capacity}
              onChange={(e) => setEditOffice({ ...editOffice, capacity: e.target.value })}
              placeholder="Edit office capacity"
            />
            <div>
              <button onClick={handleUpdateOffice}>Update</button>
              <button onClick={() => setShowEditPopup(false)}>Cancel</button>
              <button onClick={() => {
                handleDeleteOffice(editOffice.id); 
                setShowEditPopup(false); 
                setEditOffice(null); // Clear editOffice after deletion
              }}>
                <img src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png" alt="Delete" className="icon" />
              </button>
            </div>
          </div>
        )}

        {showSearchPopup && (
          <div className="edit-popup">
            <h4>Search Offices</h4>
            <input
              type="text"
              value={searchCriteria.code}
              onChange={(e) => setSearchCriteria({ ...searchCriteria, code: e.target.value })}
              placeholder="Enter office code"
            />
            <input
              type="text"
              value={searchCriteria.description}
              onChange={(e) => setSearchCriteria({ ...searchCriteria, description: e.target.value })}
              placeholder="Enter office description"
            />
            <div>
              <button onClick={handleSearchOffices}>Search</button>
              <button onClick={() => setShowSearchPopup(false)}>Cancel</button>
            </div>
          </div>
        )}

        {searchResults.length > 0 && (
          <div>
            <h3>Search Results</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Capacity</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((office) => (
                  <tr key={office.id}>
                    <td>{office.id}</td>
                    <td>{office.code}</td>
                    <td>{office.description}</td>
                    <td>{office.capacity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Offices;
