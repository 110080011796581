// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [showAdminPopup, setShowAdminPopup] = useState(false);
  const [showStaffPopup, setShowStaffPopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const navigate = useNavigate();

  const toggleAdminPopup = () => {
    setShowAdminPopup(!showAdminPopup);
  };

  const toggleStaffPopup = () => {
    setShowStaffPopup(!showStaffPopup);
  };

  const toggleUserPopup = () => {
    setShowUserPopup(!showUserPopup);
  };

  const handleAdminLogin = async (e) => {
    e.preventDefault(); 
    setErrorMessage(''); 

    try {
      const response = await fetch('login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate('/dashboard'); 
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  const handleStaffLogin = (e) => {
    e.preventDefault();
    console.log('Staff login attempted');
  };

  const handleUserLogin = (e) => {
    e.preventDefault();
    console.log('User login attempted');
  };

  return (
    <div className="login-page">
      <div className="left-section"></div>
      <div className="right-section">
        <div className="login-container">
          <h2>Login to Insight Space</h2>
          <div className="login-buttons">
            <button onClick={toggleAdminPopup} className="btn-primary">Login as Admin</button>
            <button onClick={toggleStaffPopup} className="btn-primary">Login as Staff</button>
            <button onClick={toggleUserPopup} className="btn-primary">Login as User</button>
          </div>

          {/* Admin Login Popup */}
          {showAdminPopup && (
            <div className="admin-popup">
              <div className="popup-content">
                <h3>Admin Login</h3>
                <form className="admin-login-form" onSubmit={handleAdminLogin}>
                  <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                  <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                  <button type="submit" className="btn-primary">Login</button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button onClick={toggleAdminPopup} className="close-popup">Close</button>
              </div>
            </div>
          )}

          {/* Staff Login Popup */}
          {showStaffPopup && (
            <div className="staff-popup">
              <div className="popup-content">
                <h3>Staff Login</h3>
                <form className="staff-login-form" onSubmit={handleStaffLogin}>
                  <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                  <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                  <button type="submit" className="btn-primary">Login</button>
                </form>
                <button onClick={toggleStaffPopup} className="close-popup">Close</button>
              </div>
            </div>
          )}

          {/* User Login Popup */}
          {showUserPopup && (
            <div className="user-popup">
              <div className="popup-content">
                <h3>User Login</h3>
                <form className="user-login-form" onSubmit={handleUserLogin}>
                  <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                  <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                  <button type="submit" className="btn-primary">Login</button>
                </form>
                <button onClick={toggleUserPopup} className="close-popup">Close</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
