import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = () => {
  const [menus, setMenus] = useState([]);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch the menu list from the server when the component loads
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const response = await fetch('/api/menus', {
          method: 'POST', // Ensure the correct method is used
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}), // Send an empty body if no parameters are needed
        });

        if (!response.ok) {
          throw new Error('Failed to fetch menus');
        }

        const data = await response.json();
        setMenus(data); // Set the main menus with submenus
      } catch (error) {
        console.error('Error fetching menus:', error);
        setError(error.message); // Set error message
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchMenus();
  }, []);

  // Toggle submenu visibility
  const toggleSubmenu = (menuCode) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [menuCode]: !prevState[menuCode],
    }));
  };

  if (loading) {
    return <div>Loading menus...</div>; // Loading indicator
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  return (
    <div className="sidebar">
      <h2></h2>
      <ul>
        {menus.map((menu) => (
          <li key={menu.MenuCode}>
            <div onClick={() => toggleSubmenu(menu.MenuCode)} className={`submenu-toggle ${openSubmenus[menu.MenuCode] ? 'active' : ''}`}>
              {menu.MenuName}
              {menu.subMenus.length > 0 && (
                <FontAwesomeIcon icon={faChevronDown} className="submenu-icon" />
              )}
            </div>
            {openSubmenus[menu.MenuCode] && menu.subMenus.length > 0 && (
              <ul className="submenu">
                {menu.subMenus.map((subMenu) => (
                  <li key={subMenu.MenuCode}>
                    <Link
                      to={
                        subMenu.FrmName === 'Customer' ? '/customer-form' :
                        subMenu.FrmName === 'Office' ? '/office-form' :
                        subMenu.FrmName === 'ConferenceRooms' ? '/conference-rooms' :
                        subMenu.FrmName === 'Assets' ? '/assets' :
                        subMenu.FrmName === 'Employee' ? '/employees' :
                        subMenu.FrmName === 'Visitor' ? '/visitor-management' :
                        subMenu.FrmName === 'Transaction' ? '/transaction' :
                        subMenu.FrmName === 'Contract' ? '/contract' :
                        subMenu.FrmName === 'Invoicing' ? '/invoicing' :
                        subMenu.FrmName === 'Payment' ? '/payment' :
                        subMenu.FrmName === 'Support' ? '/support' :
                        subMenu.FrmName === 'Booking' ?  '/booking':
                        `/${subMenu.FrmName}`
                      }
                    >
                      {subMenu.MenuName}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
