import React from 'react';
import './PaymentPopup.css'; // Import CSS for styling

const PaymentPopup = ({ isOpen, onClose, handlePayment }) => {
  if (!isOpen) return null;

  return (
    <div className="payment-popup-overlay">
      <div className="payment-popup">
        <div className="image-section">
          <img
            src="https://img.lovepik.com/element/45009/1953.png_860.png" 
            alt="Payment Illustration"
            className="payment-image"
          />
        </div>
        <div className="options-section">
          <h2>Choose Payment Method</h2>
          <button onClick={() => handlePayment('razorpay')}>Razorpay</button>
          <button onClick={() => handlePayment('googlePay')}>Google Pay</button>
          <button onClick={() => handlePayment('netBanking')}>Net Banking</button>
          <button onClick={() => handlePayment('upi')}>UPI</button>
          <button className="close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;
