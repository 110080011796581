import React, { useState } from 'react';
import './ConferenceRooms.css'; // Import CSS file
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';

const ConferenceRooms = () => {
  const [rooms, setRooms] = useState([
    { id: 1, code: 'CR-001', description: 'Room A', capacity: 10 },
    { id: 2, code: 'CR-002', description: 'Room B', capacity: 15 },
  ]);

  const [newRoomCode, setNewRoomCode] = useState('');
  const [newRoomDescription, setNewRoomDescription] = useState('');
  const [newRoomCapacity, setNewRoomCapacity] = useState('');
  const [showAddRoom, setShowAddRoom] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editRoom, setEditRoom] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleAddRoom = () => {
    if (newRoomCode && newRoomDescription && newRoomCapacity) {
      const newRoom = {
        id: rooms.length + 1,
        code: newRoomCode,
        description: newRoomDescription,
        capacity: parseInt(newRoomCapacity),
      };
      setRooms([...rooms, newRoom]);
      setNewRoomCode('');
      setNewRoomDescription('');
      setNewRoomCapacity('');
      setShowAddRoom(false);
    }
  };

  const handleDeleteRoom = (id) => {
    setRooms(rooms.filter(room => room.id !== id));
  };

  const handleEditRoom = (room) => {
    setEditRoom(room);
    setShowEditPopup(true);
  };

  const handleUpdateRoom = () => {
    setRooms(rooms.map(room => (room.id === editRoom.id ? editRoom : room)));
    setShowEditPopup(false);
    setEditRoom(null);
  };

  const handleSearchRooms = () => {
    const results = rooms.filter(room => {
      return (
        room.code.toLowerCase().includes(searchCriteria.toLowerCase()) ||
        room.description.toLowerCase().includes(searchCriteria.toLowerCase())
      );
    });
    setSearchResults(results);
  };

  return (
    <div className="conference-rooms">
      
      
      <div className="conference-rooms-content">
        <h3>Master creation/conferencerooms</h3>
        <h2>Conference Rooms</h2>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by code or description"
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
          />
          <button onClick={handleSearchRooms}>Search</button>
        </div>

        {showAddRoom && (
          <div className="edit-popup">
            <h4>Add New Room</h4>
            <input
              type="text"
              value={newRoomCode}
              onChange={(e) => setNewRoomCode(e.target.value)}
              placeholder="Room Code"
            />
            <input
              type="text"
              value={newRoomDescription}
              onChange={(e) => setNewRoomDescription(e.target.value)}
              placeholder="Room Description"
            />
            <input
              type="number"
              value={newRoomCapacity}
              onChange={(e) => setNewRoomCapacity(e.target.value)}
              placeholder="Room Capacity"
            />
            <div>
              <button onClick={handleAddRoom}>Submit</button>
              <button onClick={() => setShowAddRoom(false)}>Cancel</button>
            </div>
          </div>
        )}

        <button onClick={() => setShowAddRoom(!showAddRoom)} className="add-button">
          ➕ Add New Room
        </button>

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Description</th>
              <th>Capacity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(searchResults.length > 0 ? searchResults : rooms).map((room) => (
              <tr key={room.id}>
                <td>{room.id}</td>
                <td>{room.code}</td>
                <td>{room.description}</td>
                <td>{room.capacity}</td>
                <td>
                  <button onClick={() => handleEditRoom(room)}>Edit</button>
                  <button onClick={() => handleDeleteRoom(room.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditPopup && (
          <div className="edit-popup">
            <h4>Edit Room</h4>
            <input
              type="text"
              value={editRoom.code}
              onChange={(e) => setEditRoom({ ...editRoom, code: e.target.value })}
              placeholder="Room Code"
            />
            <input
              type="text"
              value={editRoom.description}
              onChange={(e) => setEditRoom({ ...editRoom, description: e.target.value })}
              placeholder="Room Description"
            />
            <input
              type="number"
              value={editRoom.capacity}
              onChange={(e) => setEditRoom({ ...editRoom, capacity: parseInt(e.target.value) })}
              placeholder="Room Capacity"
            />
            <div>
              <button onClick={handleUpdateRoom}>Update</button>
              <button onClick={() => setShowEditPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConferenceRooms;
