import React, { useState } from 'react';
import './Booking.css'; // Custom CSS for styling
import Breadcrumb from './Breadcrumb';

const Booking = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isFormVisible, setFormVisible] = useState(false);

  // Sample previous bookings data
  const previousBookings = [
    { OfficeCode: 'OC001', BPCode: 'BP001', FromDate: '2023-10-01', ToDate: '2023-10-05', Rate: 5000 },
    { OfficeCode: 'OC002', BPCode: 'BP002', FromDate: '2023-10-06', ToDate: '2023-10-10', Rate: 7500 },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleForm = () => {
    setFormVisible(!isFormVisible);
  };

  return (
    <div className="booking-container">
       <h3>Conference room/Booking</h3>
    

      <div className="dropdown" onClick={toggleDropdown}>
       
        <span className="dropdown-label">
          Previous Bookings
          <span className={`arrow ${isDropdownOpen ? 'up' : 'down'}`}></span>
        </span>
      </div>

      {isDropdownOpen && (
        <div className="dropdown-content">
          <table className="booking-table">
            <thead>
              <tr>
                <th>Office Code</th>
                <th>BP Code</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {previousBookings.map((booking, index) => (
                <tr key={index} className="booking-item">
                  <td>{booking.OfficeCode}</td>
                  <td>{booking.BPCode}</td>
                  <td>{booking.FromDate}</td>
                  <td>{booking.ToDate}</td>
                  <td>₹{booking.Rate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="form-toggle" onClick={toggleForm}>
        <span className="plus-sign">+</span> New Booking
      </div>

      {isFormVisible && (
        <form className="booking-form">
          <h3>New Booking Form</h3>
          <div>
            <label>Office Code:</label>
            <input type="text" name="OfficeCode" />
          </div>
          <div>
            <label>BP Code:</label>
            <input type="text" name="BPCode" />
          </div>
          <div>
            <label>From Date:</label>
            <input type="date" name="FromDate" />
          </div>
          <div>
            <label>To Date:</label>
            <input type="date" name="ToDate" />
          </div>
          <div>
            <label>Rate:</label>
            <input type="number" name="Rate" />
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default Booking;
