import React, { useState } from 'react';
import './EmployeeManagement.css'; // Import the CSS file for styling
import Sidebar from './Sidebar'; // Make sure this component exists
import StaffDistributionGraph from './StaffDistributionGraph';
import DashboardHeader from './DashboardHeader';

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    department: '',
    position: '',
    phone: '',
    email: '',
  });

  // Handle form input change
  const handleInputChange = (e) => {
    setNewEmployee({ ...newEmployee, [e.target.name]: e.target.value });
  };

  // Handle form submission to add a new employee
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmployees([...employees, newEmployee]);
    setNewEmployee({ name: '', department: '', position: '', phone: '', email: '' }); // Reset form
  };

  return (
    <div className="employee-management">
      <Sidebar />
      <DashboardHeader />

      <div className="employee-content">
        <header className="employee-header">
          <h1>Employee Management</h1>
          <button className="logout-button" onClick={() => console.log('Logout clicked')}>
            Logout
          </button>
        </header>

        <div className="employee-table-section">
          <h2>👥 Employee Management</h2>
          <p>Add, edit, or remove employees who can access this space.</p>

          <h3>Available Staff by Department 🏢</h3>
          {employees.length > 0 ? (
            <table className="employee-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Position</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((emp, index) => (
                  <tr key={index}>
                    <td>{emp.name}</td>
                    <td>{emp.department}</td>
                    <td>{emp.position}</td>
                    <td>{emp.phone}</td>
                    <td>{emp.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No employees added yet.</p>
          )}
        </div>

        <div className="add-employee-section">
          <h3>Add New Employee ✍️</h3>
          <form onSubmit={handleSubmit} className="add-employee-form">
            <div className="form-group">
              <label>Name:</label>
              <input type="text" name="name" value={newEmployee.name} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Department:</label>
              <input type="text" name="department" value={newEmployee.department} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Position:</label>
              <input type="text" name="position" value={newEmployee.position} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <input type="tel" name="phone" value={newEmployee.phone} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" name="email" value={newEmployee.email} onChange={handleInputChange} required />
            </div>
            <button type="submit" className="btn-primary">Add Employee</button>
          </form>
        </div>

        <div className="employee-graph-section">
          <h3>Staff Distribution Graph 📊</h3>
          {/* Placeholder for graph or 3D effect */}
          
            {/* Replace with your graph component or 3D effect here */}
            <StaffDistributionGraph /> {/* Include the StaffDistributionGraph component */}
        </div>
      </div>
    </div>
  );
};

export default EmployeeManagement;
