import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BillingDetails from './components/BillingDetails';
import EmployeeManagement from './components/EmployeeManagement';
import Assets from './components/Assets';
import ConferenceRooms from './components/ConferenceRooms';
import Offices from './components/Offices';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import DashboardHeader from './components/DashboardHeader';
import CustomerForm from './components/CustomerForm';
import OfficeForm from './components/OfficeForm';
import VisitorManagement from './components/VisitorManagement';
import Transaction from './components/Transaction';
import Contract from './components/Contract';
import Invoicing from './components/Invoicing';
import Payment from './components/Payment';
import Support from './components/Support';
import Booking from './components/Booking';
import Breadcrumb from './components/Breadcrumb';
import HomePage from './components/HomePage'; // Import the new HomePage component

const App = () => {
  return (
    <Router>
      <div>
        <Breadcrumb />
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* New HomePage route */}
          <Route path="/login" element={<Login />} />
          <Route path="/billing" element={<><DashboardHeader /><Sidebar /><BillingDetails /></>} />
          <Route path="/employees" element={<><DashboardHeader /><Sidebar /><EmployeeManagement /></>} />
          <Route path="/assets" element={<><DashboardHeader /><Sidebar /><Assets /></>} />
          <Route path="/conference-rooms" element={<><DashboardHeader /><Sidebar /><ConferenceRooms /></>} />
          <Route path="/offices" element={<><DashboardHeader /><Sidebar /><Offices /></>} />
          <Route path="/dashboard" element={<><DashboardHeader /><Sidebar /><Dashboard /></>} />
          <Route path="/booking" element={<><DashboardHeader /><Sidebar /><Booking /></>} />
          <Route path="/customer-form" element={<><DashboardHeader /><Sidebar /><CustomerForm /></>} />
          <Route path="/office-form" element={<><DashboardHeader /><Sidebar /><OfficeForm /></>} />
          <Route path="/visitor-management" element={<><DashboardHeader /><Sidebar /><VisitorManagement /></>} />
          <Route path="/transaction" element={<><DashboardHeader /><Sidebar /><Transaction /></>} />
          <Route path="/contract" element={<><DashboardHeader /><Sidebar /><Contract /></>} />
          <Route path="/invoicing" element={<><DashboardHeader /><Sidebar /><Invoicing /></>} />
          <Route path="/payment" element={<><DashboardHeader /><Sidebar /><Payment /></>} />
          <Route path="/support" element={<><DashboardHeader /><Sidebar /><Support /></>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
