// StaffDistributionGraph.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

// Sample data for the graph
const data = [
  { name: 'HR', count: 8 },
  { name: 'Engineering', count: 15 },
  { name: 'Sales', count: 10 },
  { name: 'Marketing', count: 5 },
  { name: 'Finance', count: 7 },
];

const StaffDistributionGraph = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StaffDistributionGraph;
