import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardHeader.css'; // Import any specific styles for the header
import Breadcrumb from './Breadcrumb';

const DashboardHeader = () => {
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear any authentication state if necessary
    navigate('/'); // Redirect to login page
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown((prev) => !prev);
  };

  // External image URL
  const profileImageUrl = "https://t4.ftcdn.net/jpg/04/75/00/99/360_F_475009987_zwsk4c77x3cTpcI3W1C1LU4pOSyPKaqi.jpg"; // Replace with the actual image URL

  return (
    <header className="dashboard-header">
      <h1>InsightSpace</h1>
  
      
      <div className="profile-container">
        <img 
          src={profileImageUrl} 
          alt="Profile" 
          className="profile-image" 
          onClick={toggleProfileDropdown} 
        />
        {showProfileDropdown && (
          <div className="profile-dropdown">
            <p className="profile-name">John Doe</p> {/* Replace with dynamic data */}
            <p className="profile-email">john.doe@example.com</p> {/* Replace with dynamic data */}
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
      
      
    </header>
  );
};

export default DashboardHeader;
