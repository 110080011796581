// Navbar.js
import React from 'react';
import './Navbar.css'; // Import the CSS file for Navbar

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">InsightSpace</div>
      <div className="navbar-links">
        <a href="/login">Join Us Today</a>
      </div>
    </nav>
  );
};

export default Navbar;
